import * as React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Security = () => (
  <Layout>
    <Seo title="Security" />
    <h1>Recent hacking attempt determined unsuccessful</h1>
    <p>
      ORBCoN is the subject of a recent hacking attempt by an unknown party. As
      a precaution, we had taken all involved applications offline and
      contracted a cyber security firm to investigate. We can now confirm that
      the hackers were <strong>unsuccessful</strong> in gaining access to our
      servers and user data.
    </p>
    <p>All applications involved will move back online as soon as possible.</p>
    <p>
      We sincerely apologize for any inconveniences caused by this incident.
    </p>
    <h2>Should I be concerned?</h2>
    <p>
      No, the hackers were unsuccessful in their attempts to gain access to our
      servers, and we will continue to enhance our cyber security to protect
      your personal information. Below are the types of personal information
      that we are responsible for:
    </p>
    <ul>
      <li>First name, last name, and email address</li>
      <li>Country, province</li>
      <li>Role, affiliate (hospital employer), and ward (where indicated)</li>
    </ul>
    <h2>See some best practices below:</h2>
    <p></p>
    <h3>Always keep an eye out for phishing campaigns</h3>
    <p>
      It is common for hackers to use personal information like names and email
      addresses to craft targeted phishing campaigns. Always verify that you
      know the sender of an email before opening and/or clicking on links.
      Follow your employer's guidelines on protecting yourself against phishing
      campaigns, and see some tips below from{" "}
      <a href="https://cyber.gc.ca/en/guidance/dont-take-bait-recognize-and-avoid-phishing-attacks">
        cyber.gc.ca
      </a>
      :
    </p>
    <h4>Something may be phishy if:</h4>
    <ul>
      <li>
        You don’t recognize the sender’s name, email address, or phone number{" "}
      </li>
      <li>You notice a lot of spelling and grammar errors</li>
      <li>The sender requests your personal or confidential information</li>
      <li>The sender makes an urgent request with a deadline</li>
      <li>The offer sounds too good to be true</li>
    </ul>
    <h4>Protect your information and infrastructure:</h4>
    <ul>
      <li>Verify links before you click them</li>
      <li>Avoid sending sensitive information over email or texts</li>
      <li>
        Call the sender to verify legitimacy (e.g. if you receive a call from
        your bank, hang up and call them)
      </li>
      <li>Filter spam emails</li>
    </ul>
    <h4>What do I do if I get a phishing email?</h4>
    <ul>
      <li>Do not respond</li>
      <li>Inform your supervisor</li>
      <li>Forward to your hospital's IT Security team</li>
      <li>Report the message as phishing through your webmail client</li>
      <li>
        Follow any other policies and procedures outlined by your employer
      </li>
    </ul>
    <h3>Keep your passwords updated regularly</h3>
    <p>
      Most hospitals will have guidelines on how frequently you should be
      updating your passwords. Updating your passwords at least every 90 days is
      a good rule of thumb.
    </p>
    <h4>Always make sure you:</h4>
    <ul>
      <li>Use complex passwords</li>
      <li>Don't use the same passwords across different applications</li>
      <li>If possible, use a password manager</li>
    </ul>
    <h2>Conclusion</h2>
    <p>
      ORBCoN takes the responsibility of protecting the personal information of
      our users very seriously. We will work hard to ensure our web applications
      continue to be secure and reliable, and that your information is protected.
    </p>
    <div className="link-home">
      <Link to="/">Go back to status page</Link>
    </div>
  </Layout>
)

export default Security
